







import Vue from 'vue'
import { Component } from 'vue-property-decorator'
@Component
/*
A small tag made for events. Currently it is not used in an active page.
 */
export default class Tag extends Vue {}
