





































































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import moment from 'moment'
import InfoBox from '@/components/hszg/InfoBox.vue'
import Tag from '@/components/common/Tag.vue'
import { EventSearchResult } from '@/store/search/types'
@Component({
  components: { InfoBox, Tag },
  filters: {
    moment: function (date: string, withoutTime: boolean): string {
      if (date) {
        return withoutTime ? moment(date).format('DD.MM.YYYY') : moment(date).format('DD.MM.YYYY HH:mm')
      }

      return ''
    }
  }
})
export default class EventDetail extends Vue {
  @Prop() id?: string
  @Prop() event?: EventSearchResult

  private hasEnd = true
  private bookmarked = false

  bookmark (): void {
    this.bookmarked = !this.bookmarked
    this.$root.$emit('alert', this.$t('info.success').toString(), this.bookmarked ? this.$t('events.bookmark.SUCCESS').toString() : this.$t('events.unbookmark.SUCCESS').toString(), false)
  }

  created (): void {
    if (this.event?.wholeDay && moment(this.event?.begin).isSame(this.event?.end, 'day')) this.hasEnd = false
  }
}
